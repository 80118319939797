

@import url('htpps://fonts.googleapis.com/css?family=Lato:400,700');

body {
  overflow-x: hidden;
  font-family: 'Lato', serif;
  color: #505962;
}
.offset:before {
  display: block;
  content: "";
  height: 4rem;
  margin-top: -4rem;
}
.offset ul {  
    margin: 0.75em 0;  
    padding: 0 1em;  
    list-style: none;  
    text-align: left;
}  
.offset li:before {   
    content: "";  
    border-color: transparent #008eef;  
    border-style: solid;  
    border-width: 0.35em 0 0.35em 0.45em;  
    display: block;  
    height: 0;  
    width: 0;  
    left: -1em;  
    top: 1.2em;  
    position: relative;  
} 


/*--- Navigation ---*/

.navbar {
  text-transform: uppercase;
  font-weight: 600;
  font-size: .8rem;
  letter-spacing: .1rem;
  background: rgba(0, 0, 0, 0.6)!important;
  font-family: sans-serif;
}
.navbar-brand img {
  height: 3rem;
  width: 11rem;
}
.navbar-nav li {
  padding-right: .7rem;
}
.navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover {
    color: #05C103 !important;
}
.navbar-light .navbar-nav .active>.nav-link, .navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .nav-link.show, .navbar-light .navbar-nav .show>.nav-link {
    color: #05C103 !important;
}
.nav-item a {
  border-bottom: 2px solid transparent; 
}

.nav-item a.active {
  border-bottom: 2px solid red;
}


/*----- Carousel ----*/
@-webkit-keyframes zoom {
  from {
    -webkit-transform: scale(1, 1);
  }
  to {
    -webkit-transform: scale(1.5, 1.5);
  }
}

@keyframes zoom {
  from {
    transform: scale(1, 1);
  }
  to {
    transform: scale(1.5, 1.5);
  }
}

.carousel-inner .carousel-item > img {
  -webkit-animation: zoom 0s;
  animation: zoom 0s;
  object-fit: cover;
 width: 100% !important;
 height: 655px;
}

 /* offset carousel */



/* Start of dropdown*/
.dropdown-item {
  font-size: .7rem;
}
.dropdown:hover>.dropdown-menu {
  display: block;
}

.dropdown>.dropdown-toggle:active {
  /*Without this, clicking will make it sticky*/
    pointer-events: none;
}
/* End of dropdown*/

.lead {
  text-align: left;
  margin-top: 1rem;
  font-family: serif;
}

.home-inner {
}
.caption {
  width: 100%;
  max-width: 100%;
  position: absolute;
  top: 0%;
  z-index: 1;
  color: white;
  margin-bottom: 5%;
}
.caption h1 {
  font-size: 2.8rem;
  font-weight: 500;
  letter-spacing: 0rem;
  text-shadow: .1rem .1rem .8rem black;
  padding-bottom: 1rem;
  text-align: left;
}
.caption h3 {
  font-size: 2rem;
  text-shadow: .1rem .1rem .5rem black;
  padding-bottom: 1.6rem;
  text-align: left;
}

.caption h2 {
  font-size: 4.4rem;
  color: red;
  text-align: left;
  text-shadow: .1rem .1rem .5rem black;
}

.caption a {
  background-color: #26DC1E;
  float: left;
}
.btn-lg {
  border-width: medium;
  border-radius: 0;
  padding: .6rem 1.3rem;
  font-size: 1.1rem;
}


// FAQ
.content {
  width: 80%;
  padding: 20px;
  margin: 0 auto;
  padding: 0 60px 0 0;
}

.centerplease {
  margin: 0 auto;
  max-width: 270px;
  font-size: 40px;
}

.question {
  position: relative;
  background: #A5AD03;
  color: white;
  font-size: 1.2rem;
  margin: 0;
  padding: 10px 10px 10px 50px;
  display: block;
  width:100%;
  cursor: pointer;
}

.questions {
  position: relative;
  background: red;
  color: white;
  font-size: 1.2rem;
  margin: 0;
  padding: 10px 10px 10px 50px;
  display: block;
  width:100%;
  cursor: pointer;
}


.answers {
  background: #E1E4D8;
  text-align: left;
  padding: 0px 15px;
  margin: 5px 0;
  max-height: 0;
  overflow: hidden;
  z-index: -1;
  position: relative;
  opacity: 0;
  -webkit-transition: .7s ease;
  -moz-transition: .7s ease;
  -o-transition: .7s ease;
  transition: .7s ease;
}

.questions:checked ~ .answers{
  max-height: 500px;
  opacity: 1;
  padding: 15px;
}

.plus {
  position: absolute;
  margin-left: 10px;
  color: white;
  z-index: 5;
  font-size: 2em;
  line-height: 100%;
  -webkit-user-select: none;    
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  -webkit-transition: .3s ease;
  -moz-transition: .3s ease;
  -o-transition: .3s ease;
  transition: .3s ease;
}

.questions:checked ~ .plus {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

.questions {
  display: none;
}


/* Course Section */
.narrow {
  width: 100%;
  margin: 1.5rem auto;
  padding-top: 2rem;
}
.narrow h1 {
  font-size: 2.4rem;
}
.btn-secondary {
  border-width: medium;
  border-radius: 0;
  text-transform: uppercase;
  font-size: 1.2rem;
  padding: .6rem 1.2rem;
  margin: 1rem;
  color: white;
  background-color: #1ebba3;
  border-color: #1ebba3;
}
.btn-secondary:hover {
  background-color: #189582;
  border-color: #189582;
}
.welcome {
  margin: 2rem;
}
.welcome h1 {
  text-align: left;
    color: red;
  text-shadow: .1rem .1rem .1rem black;
}
.welcome h2 {
  color: #999200;
  text-align: left;
}

/* Start of text over image hover*/
@import url(https://fonts.googleapis.com/css?family=Raleway:400,200,300,800);
figure.snip0018 {
  font-family: 'Raleway', Arial, sans-serif;
  color: #fff;
  position: relative;
  overflow: hidden;
  margin: 10px;
  width: 100%;
  background: #000000;
  text-align: center;
}
figure.snip0018 * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
figure.snip0018 img {
  opacity: 1;
  width: 100%;
  -webkit-transition: opacity 0.35s;
  transition: opacity 0.35s;
}
figure.snip0018 figcaption {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
figure.snip0018 figcaption > div {
  height: 50%;
  overflow: hidden;
  width: 100%;
  position: relative;
}
figure.snip0018 figcaption::before {
  position: absolute;
  top: 50%;
  right: 50%;
  left: 50%;
  height: 2px;
  content: '';
  opacity: 0;
  background-color: rgba(255, 255, 255, 0);
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  -webkit-transition-delay: 0.6s;
  transition-delay: 0.6s;
}
figure.snip0018 h2,
figure.snip0018 p {
  margin: 0;
  padding: 30px 35px;
  opacity: 0;
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  -webkit-transition: opacity 0.4s, -webkit-transform 0.4s;
  transition: opacity 0.4s,-webkit-transform 0.4s,-moz-transform 0.4s,-o-transform 0.4s,transform 0.4s;
}
figure.snip0018 h2 {
  display: inline-block;
  word-spacing: -0.1em;
  font-weight: 300;
  text-transform: uppercase;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  -webkit-transform: translate3d(0%, 50%, 0);
  transform: translate3d(0%, 50%, 0);
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}
figure.snip0018 h2 span {
  font-weight: 800;
}
figure.snip0018 p {
  font-weight: 300;
  top: 0%;
  color: #000;
  background: #fff;
  -webkit-transform: translate3d(0%, -50%, 0);
  transform: translate3d(0%, -50%, 0);
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}
figure.snip0018 a {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  position: absolute;
  color: #ffffff;
}
figure.snip0018:hover img {
  opacity: 0.7;
}
figure.snip0018:hover figcaption h2,
figure.snip0018:hover figcaption p {
  -webkit-transform: translate3d(0%, 0%, 0);
  transform: translate3d(0%, 0%, 0);
  -webkit-transition-delay: 0.3s;
  transition-delay: 0.3s;
}
figure.snip0018:hover figcaption h2 {
  opacity: 1;
}
figure.snip0018:hover figcaption p {
  opacity: 1;
}
figure.snip0018:hover figcaption::before {
  background: #ffffff;
  left: 0px;
  right: 0px;
  opacity: 1;
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}
/* End of text over image hover*/



/* Features Section */
.jumbotron {
  margin-bottom: 0;
  padding: 2rem 0 3.5rem;
  border-radius: 0;
}
h3.heading {
  font-size: 1.9rem;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 1.7rem;
  color: #04A78A;
  text-shadow: .1rem .1rem .1rem black;
}
.heading-underline {
  width: 3rem;
  height: .2rem;
  background-color: #1ebba3;
  margin: 0 auto 2rem;
}
.boxed {
  border: 4px solid #DAFA58;
  margin-top: 2.5rem;
  padding: .5rem;
}
.boxed {
  margin-left: 0rem;
}
.boxed li {
  font-family: 'Lato',sans-serif;
  font-size: 18px;
}
.boxed p {
  font-family: 'Lato', sans-serif;
  font-size: 18px;
}
.feature svg.svg-inline--fa {
  color: #1ebba3;
}
.feature h3 {
  font-size: 1.3rem;
  text-transform: uppercase;
  margin-bottom: .8rem;
  margin-top: .8rem;
  color: red;
  text-shadow: .1rem .1rem .1rem black;
}
.feature p {
  font-size: 1.1rem;
  text-align: left;
}
.feature img {
  max-width: 100%;
}
.feature li {
  text-align: left;
}



/*--- Resources Section ---*/

.dark {
  background-color: rgba(0, 0, 0, 0.75);
  color: white;
  padding: 7rem, 2rem;
}
.fixed-background h3 {
  margin-bottom: 2rem;
}
.fixed-background p {
  margin-top: 1.5rem;
}

/*--- Clients Section ---*/
.clients img {
  width: 100%;
  border-radius: 50%;
}
blockquote .svg-inline--fa {
  color: #1ebba3;
  margin: 1rem;
}
.clients-hr {
  border-top: .05rem solid #1ebba3;
}
.contact a {
  font-size: 4rem;
  padding: 1rem;
}


/*--- whatsapp ---*/
.float{
  position:fixed;
  width:60px;
  height:60px;
  bottom:40px;
  right:40px;
  background-color:#4CFA00;
  color:#FFF;
  border-radius:50px;
  text-align:center;
  font-size:30px;
  box-shadow: 2px 2px 3px #999;
  z-index:100;
}

.my-float{
  margin-top:16px;
}


/*--- Contact Section ---*/
.site-footer
{
  background-color:#40474e;
  padding:45px 0 20px;
  font-size:15px;
  line-height:24px;
  color:white;
}
.site-footer hr
{
  border-top-color:#bbb;
  opacity:0.5
}
.site-footer hr.small
{
  margin:20px 0
}
.site-footer h6
{
  color:#fff;
  font-size:16px;
  text-transform:uppercase;
  margin-top:5px;
  letter-spacing:2px;
  text-decoration: underline;
}
.site-footer a
{
  color:#737373;
}
.site-footer a:hover
{
  color:white;
  text-decoration:underline;
}
.footer-links
{
  padding-left:0;
  list-style:none
}
.footer-links li
{
  display:block
}
.footer-links a
{
  color:white;
  font-size: 12px;
}
.footer-links a:active,.footer-links a:focus,.footer-links a:hover
{
  color:#fff;
  text-decoration:underline;
}
.footer-links.inline li
{
  display:inline-block
}
.site-footer .social-icons
{
  text-align:right
}
.site-footer .social-icons a
{
  width:40px;
  height:40px;
  line-height:40px;
  margin-left:6px;
  margin-right:0;
  border-radius:100%;
  background-color:#33353d
}
.copyright-text
{
  margin:0
}
@media (max-width:991px)
{
  .site-footer [class^=col-]
  {
    margin-bottom:30px
  }
}
@media (max-width:767px)
{
  .site-footer
  {
    padding-bottom:0
  }
  .site-footer .copyright-text,.site-footer .social-icons
  {
    text-align:center
  }
}
.social-icons
{
  padding-left:0;
  margin-bottom:0;
  list-style:none
}
.social-icons li
{
  display:inline-block;
  margin-bottom:4px
}
.social-icons li.title
{
  margin-right:15px;
  text-transform:uppercase;
  color:#96a2b2;
  font-weight:700;
  font-size:13px
}
.social-icons a{
  background-color:#eceeef;
  color:#818a91;
  font-size:16px;
  display:inline-block;
  line-height:44px;
  width:44px;
  height:44px;
  text-align:center;
  margin-right:8px;
  border-radius:100%;
  -webkit-transition:all .2s linear;
  -o-transition:all .2s linear;
  transition:all .2s linear
}
.social-icons a:active,.social-icons a:focus,.social-icons a:hover
{
  color:#fff;
  background-color:#29aafe
}
.social-icons.size-sm a
{
  line-height:34px;
  height:34px;
  width:34px;
  font-size:14px
}
.social-icons a.facebook:hover
{
  background-color:#3b5998
}
.social-icons a.twitter:hover
{
  background-color:#00aced
}
.social-icons a.linkedin:hover
{
  background-color:#007bb6
}
.social-icons a.dribbble:hover
{
  background-color:#ea4c89
}
.social-icons li:before {
  border-width: 0em 0 0em 0em;
}
@media (max-width:767px)
{
  .social-icons li.title
  {
    display:block;
    margin-right:0;
    font-weight:600
  }
}
hr.socket {
  width: 100%;
  border-top: .2rem solid #666b71;
  margin-top: 3rem;
}


/*--- Media Queries --*/

@media (max-width: 767px) {
.caption h1 {
  font-size: 2.3rem;
  letter-spacing: .15rem;
  padding-bottom: .5rem;
}
.caption h3 {
  font-size: 1rem;
  padding-bottom: 1rem;
}
.caption h2{
  font-size: 2.5rem;
}
.btn-lg {
  padding: .5rem 1rem;
  font-size: 1rem;
}
.narrow h1 {
  font-size: 1.5rem;
}
.clients img {
  max-width: 50%;
  display: block;
  margin: 0 auto;
}
.carousel-inner .carousel-item > img {
  width: 100%;
  height: 370px;
}
.patners img {
  width: 50%;
  padding-bottom: 15px;
}
}


/*======== BOOTSTRAP BREAK POINTS:

Extra small (xs) devices (portrait phones, less than 576px)
No media query since this is the default in Bootstrap

Small (sm) devices (landscape phones, 576px and up)
@media (min-width: 576px) { ... }

Medium (md) devices (tablets, 768px and up)
@media (min-width: 768px) { ... }

Large (lg) devices (desktops, 992px and up)
@media (min-width: 992px) { ... }

Extra (xl) large devices (large desktops, 1200px and up)
@media (min-width: 1200px) { ... } ========*/
.shadow-effect {
    transition: all 0.5s;
}

.shadow-effect:hover {
    box-shadow: -6px 6px 0 0 var(--color-brand);
}
/* CARD */
.card-custom .card-custom-image {
    overflow: hidden;
}

.card-custom .card-custom-image img {
    transition: all 0.4s ease;
    max-width: 100%;
    max-height: 100%;
}

.card-custom:hover .card-custom-image img {
    transform: scale(1.1);
}
.link-custom {
    font-weight: 700;
    position: relative;
}

.link-custom::after {
    content: "";
    width: 0%;
    height: 2px;
    background-color: var(--color-brand);
    position: absolute;
    left: 0;
    top: 110%;
    transition: all 0.4s;
}

.link-custom:hover::after {
    width: 100%;
}

.full-height {
    min-height: 100vh;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 80px;
    padding-bottom: 80px;
    border-bottom: 2px solid rgba(255,255,255, 0.1);
}